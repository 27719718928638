@import "src/theme/variables.scss";

html,
body {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("InterRegular"),
    url(../../assets/fonts/InterRegular.ttf) format("ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("InterExtrabold"),
    url(../../assets/fonts/InterExtraBold.ttf) format("ttf");
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $background;
  bottom: 0;
  color: $fontColor;
  font-family: "Inter", monospace;
  font-size: $mediumFontSize;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  text-rendering: geometricPrecision;
  top: 0;
}

body > div:first-child {
  display: flex;
  min-height: 100%;
}