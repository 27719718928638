$background: #FEF2D6;
$border: #EDEEEF;
$connectedColor: #008C73;
$disabled: #5D6D74;
$error: #FB7C6D;
$fancy: #FB7C6D;
$fontColor: #111214;
$primary: #111214;
$secondary: #1AB775;
$secondaryTextOrSvg: #B2B5B2;
$secondaryBackground: #F8F9F9;
$warning: #ffc05f;
$fancy: #FB7C6D;
$secondary: #1AB775;

$headerHeight: 52px;
$marginButtonImg: 12px;

$lg: 24px;
$md: 16px;
$sm: 8px;
$xl: 32px;
$xs: 4px;
$xxl: 40px;
$xxlFontSize: 32px;

$bolderFont: 500;
$extraBoldFont: 800;

$fontSizeHeadingLg: 32px;
$fontSizeHeadingMd: 20px;
$fontSizeHeadingSm: 16px;
$fontSizeHeadingXs: 13px;
$mediumFontSize: 14px;

$smallFontSize: 12px;
$largeFontSize: 16px;
$extraLargeFontSize: 20px;
$extraSmallFontSize: 11px;


$lightFont: 300;
$regularFont: 400;
$boldFont: 700;

$screenLg: 1200;
$screenMd: 992;
$screenSm: 768;
$screenXs: 480;